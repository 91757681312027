.auth-page {
    min-height: 100vh;
    background-image: url(../assets/auth-bg.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    font-family: 'Satoshi';
  }
  
  .auth-logo {
    margin-bottom: 50px;
    display: block;
  }
  
  .auth-logo img {
    height: 40px;
  }
  
  .auth-container h5 {
    color: #b8b8b8;
    text-transform: uppercase;
    font-size: 1.1rem;
  }
  
  .auth-container h1 {
    color: #fff;
    font-weight: 600;
    margin: 10px 0 !important;
  }
  
  .auth-container h6 {
    color: #b8b8b8;
    margin-bottom: 30px !important;
  }
  
  .auth-form {
    display: flex;
    flex-wrap: wrap;
  }
  
  .auth-form-input {
    width: 100%;
    height: 50px;
    border: 1px solid transparent;
    transition: .5s;
    background-color: #69b3e746;
    backdrop-filter: blur(5px);
    border-radius: 15px;
    outline: none;
    padding: 0 15px;
    margin-bottom: 10px;
    color: #fff;
    font-size: .9rem;
  }
  
  .auth-form-input::placeholder {
    color: #b8b8b8;
  }
  
  .auth-form-input:focus {
    border-color: #337CCF;
  }
  
  .auth-form-btn {
    border-radius: 15px;
    height: 50px;
    border: none;
    width: 100%;
    outline: none;
    background: linear-gradient(to right, #337CCF, #16375c);
    color: #fff;
    font-size: .9rem;
  }

  .plan-card {
    width: 100%;
    height: 100%;
    border: 1px solid #337CCF;
    padding: 15px;
    background-color: #337ccf2e;
    backdrop-filter: blur(10px);
    cursor: pointer;
    transition: .5s;
    border-radius: 15px;
}

.plan-card:hover {
    background-color: #337ccf5c;
  }

  .plan-card img {
    height: 35px;
  }

  .plan-card h1 {
    font-size: 1.2rem;
    color: #fff;
    margin-top: 10px;
  }

  .plan-card p {
    color: lightgray;
    font-size: 1rem;
  }

  .plan-card h6 {
    font-size: .9rem;
    color: #fff;
    margin-bottom: 0 !important;
  }

  .plan-card h6 span {
    color: #337CCF;
  }

  .plan-card.active {
    background-color: #337CCF;
  }

  .plan-card.active img {
    filter: brightness(0) invert(1);
  }

  .plan-card.active h6 span {
    color: #000;
    font-weight: 600;
  }

  .signup-checks input {
    transition: .3s;
    cursor: pointer;
  }

.signup-checks input:checked {
    background-color: #337CCF !important;
    border-color: #337CCF;
}

.signup-checks .form-check {
    margin-top: 10px;
}

.signup-checks label {
    color: #fff;
    cursor: pointer;
}

.auth-message {
  color: #fff;
  margin-top: 15px;
  font-size: .9rem;
  a {
    color: #6fb2ff;
  }
}