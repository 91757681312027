@font-face {
    font-family: 'General Sans';
    src: url('../assets/GeneralSans-Regular.ttf');
}

@import 'variables';
@import 'components';
@import 'home';
@import 'playlists';
@import 'distribution.scss';
@import 'privacyPolicy.scss';
@import 'dashboard.scss';
@import 'admin.scss';

@font-face {
    font-family: 'Satoshi';
    src: url('../assets/Satoshi-Regular.ttf');
}

@font-face {
    font-family: 'Optien';
    src: url('../assets/Optien.ttf');
}

* {
    position: relative;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: #000;
}

.main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
}

.flex-grow {
    flex-grow: 1;
}

.row {
    display: flex;
}

a {
    cursor: pointer;
    text-decoration: none;
}

a:hover {
    text-decoration: none !important;
}